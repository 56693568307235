import { createApiPortfolioChartDataUrl } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";
import { IPortfolioChartResponse } from "./portfolio-chart.api.types";

export function usePortfolioChartQuery(wikifolioId: string, retry = 0) {
    return useQuery<IPortfolioChartResponse>(createApiPortfolioChartDataUrl(wikifolioId), {
        queryParams: new URLSearchParams({ includePortfolio: "true" }),
        retry: retry,
    });
}
