import { useRef } from "react";
import { Box, BoxProps, Flex, Grid, SimpleGrid, Text } from "@chakra-ui/react";
import Highstock from "highcharts/highstock";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { useTranslation } from "next-i18next";
import { EnumAssetType } from "src/api/client/portfolio-chart.api.types";
import { IPortfolioChartDict } from "src/api/server/wf-detail.types";
import { FullSizeFlexCentered } from "src/components/base";
import { IconHidden } from "src/components/icons";
import { useAssetColor } from "src/hooks/portfolio/use-asset-color";
import { useWeightageFormatter } from "src/hooks/portfolio/use-weightage-formatter";
import { useIsomorphicLayoutEffect } from "src/utils/common-util";
import { logger } from "src/utils/logger-util";
import { PORTFOLIO_CHART_HEIGHT, PORTFOLIO_CHART_TOOLTIP_SX } from "./configs";
import { LoadingState } from "./loading-state";
import { usePortfolioChart } from "./use-portfolio-chart";

const UNKNOWN_ASSET_TYPE_ERROR_MESSAGE = "Unknown asset type:";

const getAssetGroupLabel = (type: EnumAssetType, dict: IPortfolioChartDict) => {
    switch (type) {
        case EnumAssetType.Stock:
            return dict.stock;
        case EnumAssetType.ETF:
            return dict.etf;
        case EnumAssetType.Derivatives:
            return dict.derivatives;
        case EnumAssetType.Wikifolios:
            return dict.wikifolios;
        case EnumAssetType.Cash:
            return dict.cash;
        default:
            logger.error({ message: UNKNOWN_ASSET_TYPE_ERROR_MESSAGE, type });
            return "";
    }
};

const getColumnsXs = (assetGroupsLength: number) => (assetGroupsLength === 1 ? 1 : 2);

const EmptyState = () => {
    const { t } = useTranslation("common");

    return (
        <Box pb={7} pointerEvents="none">
            <Box h={3} mt={4} rounded="4px" bg="gray.100" />
            <SimpleGrid columns={[2, 4]}>
                {[1, 2, 3, 4].map(key => (
                    <Box key={key} w={10} h={2} mt={2} mx="auto" rounded="4px" bg="gray.100" />
                ))}
            </SimpleGrid>
            <FullSizeFlexCentered direction="column" top={[4, 0]}>
                <IconHidden boxSize="136px" />
                <Text fontSize="lg" fontWeight="semibold" mt={2}>
                    {t("no-data")}
                </Text>
            </FullSizeFlexCentered>
        </Box>
    );
};

const CHART_SX = {
    mt: -1,
    ["& *"]: {
        fontFamily: "body",
    },
    ...PORTFOLIO_CHART_TOOLTIP_SX,
};

interface IAssetGroupProps {
    value: string;
    color: string;
    label: string;
    count?: number;
}
const AssetGroup = ({ value, color, label, count }: IAssetGroupProps) => (
    <Flex direction="column" align="center">
        <Box fontSize="xl" fontWeight="bold">
            {value}
        </Box>
        <Flex align="center">
            <Box fontSize="xs" color={color} mr={0.5}>
                ●
            </Box>
            <Box fontSize={["xs", "md"]}>
                {label}
                {count && <> ({count})</>}
            </Box>
        </Flex>
    </Flex>
);

export interface IPortfolioChartProps extends BoxProps {
    wikifolioId: string;
    dict: IPortfolioChartDict;
}

export const PortfolioChart = ({ wikifolioId, dict }: IPortfolioChartProps) => {
    const { t } = useTranslation(["common", "wf-detail"]);
    const formatWeightage = useWeightageFormatter();
    const colorMap = useAssetColor();
    const ref = useRef<HTMLDivElement | null>(null);
    const { isLoading, isError, hasWeighting, config, assetGroups, amountSum } = usePortfolioChart(wikifolioId);

    useIsomorphicLayoutEffect(() => {
        HighchartsAccessibility(Highstock);

        if (!ref.current || !config) {
            return;
        }

        Highstock.setOptions({
            lang: {
                accessibility: {
                    svgContainerLabel: t("labels.portfolio-chart-aria-label", { ns: "wf-detail" }),
                },
            },
        });
        Highstock.chart(ref.current, config);
    });

    const columnXs = assetGroups ? getColumnsXs(assetGroups.length) : undefined;

    return (
        <>
            {isLoading && <LoadingState />}
            {!isLoading && (isError || !hasWeighting) && <EmptyState />}
            {config && assetGroups && amountSum && (
                <>
                    <Box pointerEvents="fill" ref={ref} h={PORTFOLIO_CHART_HEIGHT + "px"} sx={CHART_SX} />
                    <Grid templateColumns={[`repeat(${columnXs}, 1fr)`, `repeat(${assetGroups.length}, auto)`]} rowGap={2} mt={0.5}>
                        {assetGroups.map((group, i) => (
                            <AssetGroup
                                key={i}
                                value={formatWeightage(group.amount / amountSum, 1)}
                                color={colorMap[group.assetType]}
                                label={getAssetGroupLabel(group.assetType, dict)}
                                count={group.assetType === EnumAssetType.Cash ? undefined : group.totalUnderlyings}
                            />
                        ))}
                    </Grid>
                </>
            )}
        </>
    );
};
