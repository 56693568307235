import { EnumAssetType, IPortfolioChartResponse } from "src/api/client/portfolio-chart.api.types";
import { calcPercentagesWithOthers } from "./calc-percentage-with-others";

export interface IAsset {
    assetType: EnumAssetType;
    name: string;
    amount: number;
    allocPercentage: number;
    realPercentage: number;
}

interface IAssetGroup {
    assetType: EnumAssetType;
    totalUnderlyings: number;
    underlyings: IAsset[];
    amount: number;
    allocPercentage: number;
    realPercentage: number;
}

interface IArgs {
    data: IPortfolioChartResponse;
    othersLabel: string;
    /** The minimum optical percentage */
    minPercentage?: number;
    /** After the apply of the minPercentage,
     *  do not create the "Others" slice if the difference of the new total percentage
     *  it up to this percentage. i.e. 5% means if the new percentage is up to 105% do
     *  not create the "Others" but shrink them all.
     */
    othersTolerancePercentage?: number;
}

/**
 * Group the underlyings by their asset type.
 * Each underlying will have a minPercentage for the optical representation.
 * For the underlyings of each one asset type, create the "Others" slice aggregating the underlyings that don't fit.
 * Note: if an underlying has negative value, this value is changed to 0.00001 by default, in order to be shown in the chart as <1.0%.
 * @returns {IAssetGroup[]} - Return the AssetGroup for the chart.
 */
export const convertUnderlyingsToAssetGroupsWithOthers = (args: IArgs): IAssetGroup[] => {
    const { data, othersLabel, minPercentage = 1, othersTolerancePercentage = 5 } = args;

    const assetGroups = createAssetGroups(data);
    calcAllocPercentages(assetGroups, othersLabel, minPercentage, othersTolerancePercentage);
    return assetGroups;
};

/**
 * Group the underlyings by their asset to asset groups.
 * @param {IAsset[]} underlyings - The underlying to be grouped.
 * @returns {IAssetGroup[]} - The underlyings grouped.
 */
const createAssetGroups = (data: IPortfolioChartResponse): IAssetGroup[] => {
    const assetGroupMap = new Map<EnumAssetType, IAssetGroup>();

    // Create the asset groups dictionary
    data.portfolio.underlyings.forEach(underlying => {
        // Skip if the underlying has negative value
        if (underlying.amount < 0) {
            return;
        }

        const groupUnderlying: IAsset = { ...underlying, allocPercentage: 0, realPercentage: 0 };
        const assetGroup = assetGroupMap.get(underlying.assetType);
        if (!assetGroup) {
            // create the group if it doesn't exist
            assetGroupMap.set(groupUnderlying.assetType, {
                assetType: groupUnderlying.assetType,
                totalUnderlyings: 1,
                underlyings: [groupUnderlying],
                amount: groupUnderlying.amount,
                allocPercentage: 0,
                realPercentage: 0,
            });
        } else {
            // update it
            assetGroup.underlyings.push(groupUnderlying);
            assetGroup.amount += groupUnderlying.amount;
            assetGroup.totalUnderlyings++;
        }
    });

    const result: (IAssetGroup | undefined)[] = [];
    // schedule the order of the asset-group within the bar chart
    if (data.portfolio.isSuperWikifolio) {
        result.push(assetGroupMap.get(EnumAssetType.Wikifolios));
    } else {
        result.push(assetGroupMap.get(EnumAssetType.Stock));
        result.push(assetGroupMap.get(EnumAssetType.ETF));
        result.push(assetGroupMap.get(EnumAssetType.Derivatives));
    }
    result.push(assetGroupMap.get(EnumAssetType.Cash));

    return result.filter(Boolean) as IAssetGroup[]; // :-(
};

/**
 * Calculate the allocation percentage (the optical percentage), applying min percentage for each one and aggregate the underlyings that don't fit.
 * @param {IAssetGroup[]} assetGroups - The grouped underlyings to asset groups
 * @param {string} othersLabel - Label of the "Others" slice
 * @param {number} minPercentage - Minimum optical percentage for each underlying.
 * @param {number} othersTolerancePercentage - After the apply of the minPercentage,
 *  do not create the "Others" slice if the difference of the new total percentage
 *  it up to this percentage. i.e. 5% means if the new percentage is up to 105% do
 *  not create the "Others" but shrink them all.
 */
const calcAllocPercentages = (assetGroups: IAssetGroup[], othersLabel: string, minPercentage: number, othersTolerancePercentage: number): void => {
    const underlyingCountMap = new Map<EnumAssetType, number>();

    // Get the underlying counts
    assetGroups.forEach(assetGroup => underlyingCountMap.set(assetGroup.assetType, assetGroup.underlyings.length));

    // Calculate the percentages for each asset group
    calcPercentagesWithOthers({
        items: assetGroups,
        minPercentage,
        percentageBase: 100,
        othersTolerancePercentage,
        skipSort: true,
    });

    // Calculate the percentages for the underlyings of each one asset group
    assetGroups.forEach((assetGroup: IAssetGroup) => {
        // The percentage base is the assetGroup.allocPercentage!
        calcPercentagesWithOthers({
            items: assetGroup.underlyings,
            minPercentage,
            percentageBase: assetGroup.allocPercentage,
            othersTolerancePercentage,
            skipSort: false,
        });

        // Add asset type and name for the others asset
        if (!assetGroup.underlyings[0].assetType) {
            const othersCount: number = underlyingCountMap.get(assetGroup.assetType)! - (assetGroup.underlyings.length - 1);
            assetGroup.underlyings[0].assetType = assetGroup.assetType;
            assetGroup.underlyings[0].name = `${othersLabel} (${othersCount})`;
        }

        // Reverse the assetGroup.underlyings, since the calc function returns the smallers first
        assetGroup.underlyings.reverse();
    });
};
