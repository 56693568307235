import { Options, Point, SeriesBarOptions, SeriesOptionsType, TooltipPositionerPointObject } from "highcharts/highstock";
import { IAsset } from "./chart-utils";

interface IAssetTooltipData {
    color: string;
    name: string;
    percentageFormatted: string;
}
export const getAssetSeries = (underlying: IAsset, color: string, percentageFormatted: string): SeriesBarOptions & { custom: IAssetTooltipData } => ({
    type: "bar",
    name: underlying.name,
    data: [underlying.allocPercentage],
    color,
    custom: {
        color,
        name: underlying.name,
        percentageFormatted,
    },
});

const CLASS_PORTFOLIO_CHART_TOOLTIP = "portfolio-chart-tooltip";
export const PORTFOLIO_CHART_TOOLTIP_SX = {
    ["." + CLASS_PORTFOLIO_CHART_TOOLTIP]: {
        h: 4,
        display: "flex",
        alignItems: "center",
        color: "white",
        px: 1,
        bg: "gray.800",
        rounded: "8px",
        fontSize: "xs",
        "> span:nth-of-type(1)": {
            flexShrink: 0,
            mr: 0.5,
        },
        "> span:nth-of-type(2)": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: 600,
        },
        "> span:nth-of-type(3)": {
            flexShrink: 0,
            ml: 2,
        },
    },
};
const getTooltipHtml = (point: Point) => {
    const underlying = point.series.options.custom as IAssetTooltipData;

    return `<div class="${CLASS_PORTFOLIO_CHART_TOOLTIP}">
        <span style="color:${underlying.color}">●</span>
        <span>${underlying.name}</span>
        <span>${underlying.percentageFormatted}</span>
    </div>`;
};

interface IExtendedTooltipPositionerPointObject extends TooltipPositionerPointObject {
    h: number;
}

export const PORTFOLIO_CHART_HEIGHT = 74;
export const getPortfolioChartConfig = (series: SeriesOptionsType[]): Options => ({
    chart: {
        type: "bar",
        spacing: [27, 0, 0, 0],
        height: PORTFOLIO_CHART_HEIGHT,
        backgroundColor: "transparent",
    },
    accessibility: {
        point: {
            descriptionFormatter: point => `${point.series.name} ${point.percentage?.toFixed(2)}%`,
        },
    },
    plotOptions: {
        bar: {
            borderWidth: 2,
        },
        series: {
            stacking: "percent",
            accessibility: {
                descriptionFormat: "",
            },
        },
    },
    // Disable Title, Credits, Legends, RangeSelector and Exporting
    title: { text: undefined },
    credits: { enabled: false },
    legend: { enabled: false },
    exporting: { buttons: { contextButton: { enabled: false } } },
    // Define Axis
    xAxis: {
        tickLength: 0,
        lineWidth: 0,
        labels: {
            enabled: false,
        },
    },
    yAxis: {
        title: { text: null },
        gridLineWidth: 0,
        labels: {
            enabled: false,
        },
    },
    tooltip: {
        useHTML: true,
        borderWidth: 0,
        padding: 2,
        distance: 14,
        shadow: false,
        headerFormat: "",
        shape: "square",
        positioner: function (w, _, point) {
            const chartW = this.chart.chartWidth;
            // Center tooltip
            let x = point.plotX - (point as IExtendedTooltipPositionerPointObject).h / 2 - w / 2;

            // Ensure the tooltip is not overflowing the chart
            if (x < 0) {
                x = 0;
            } else if (chartW < x + w) {
                x = chartW - w;
            }

            return { x, y: 0 };
        },
        pointFormatter: function () {
            return getTooltipHtml(this);
        },
    },
    series,
});
