import { useMemo } from "react";
import { useToken } from "@chakra-ui/react";
import { EnumAssetType } from "src/api/client/portfolio-chart.api.types";
import { EnumAssetType as EnumAssetType2 } from "src/api/client/portfolio-table.api";

export const useAssetColor = () => {
    const [green600, lilac600, orange600, yellow600, blue600] = useToken("colors", [
        "green.600",
        "lilac.600",
        "orange.600",
        "yellow.600",
        "blue.600",
    ]) as string[];

    return useMemo(
        () => ({
            [EnumAssetType.Stock]: green600,
            [EnumAssetType.ETF]: lilac600,
            [EnumAssetType.Derivatives]: orange600,
            [EnumAssetType.Wikifolios]: yellow600,
            [EnumAssetType.Cash]: blue600,
            // number based
            [EnumAssetType2.Stocks]: green600,
            [EnumAssetType2.ETFs]: lilac600,
            [EnumAssetType2.Derivatives]: orange600,
            [EnumAssetType2.Wikifolios]: yellow600,
            [EnumAssetType2.Cash]: blue600,
        }),
        [blue600, green600, lilac600, orange600, yellow600]
    );
};
