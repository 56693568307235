import { useCallback } from "react";
import { IFractionDigits } from "../formatters/number-formatter";
import { useNumberFormatter } from "../formatters/use-number-formatter";

export const useWeightageFormatter = () => {
    const { formatPercent } = useNumberFormatter();

    return useCallback(
        (weightPercent: number, fractionDigits?: IFractionDigits) => {
            if (weightPercent <= 0.01) {
                return "<" + formatPercent(0.01, fractionDigits);
            } else if (0.999 < weightPercent && weightPercent < 1) {
                return formatPercent(0.999, fractionDigits);
            } else {
                return formatPercent(weightPercent, fractionDigits);
            }
        },
        [formatPercent]
    );
};
