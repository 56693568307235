export enum EnumAssetType {
    Stock = "Stock",
    ETF = "ETF",
    Derivatives = "Derivatives",
    Wikifolios = "Wikifolios",
    Cash = "Cash",
}

interface IAsset {
    assetType: EnumAssetType;
    name: string;
    amount: number;
}

export interface IPortfolioChartResponse {
    portfolio: {
        isSuperWikifolio: boolean;
        hasWeighting: boolean;
        underlyings: IAsset[];
    };
}
