/*
 * This algorithm takes an array of IValuePercentagesItem and mutates
 * the array updating the realPercentage and allocPercentage.
 *
 * The realPercentage is the actual percent of item in the given percentageBase (default is 100%).
 * and the allocPercentage is the allocation percentage for this item for the charts.
 * The allocPercentage is bigger or equal to minPercentage.
 *
 * If at the end, there is no space in the percentageBase for all items, a new item is created as first one.
 * This is the "Others" item where aggregates all the items where cannot fit.
 *
 * The algorithm is not "detecting" the assets for the "Others" but is pushing
 * the assets to the "Others" if they don't fit in the chart.
 *
 * This is how it works:
 * - if the percentage of an asset is <1% then make it 1%
 * - this is increasing the amount of percentage to be more that 100%
 * - the algorithm takes all these assets (above the 100%) and adds it in the "others"
 *
 * There is a tolerance (of 5%) where when the sum of the percentages is up to 105%,
 * it doesn't create the "Others" slice but it shrinks all the assets.
 * Thanks to this we don't see the "others" slice when small changes made only.
 */

export interface IValuePercentagesItem {
    amount: number;
    realPercentage: number; // the actual percentage of an item
    allocPercentage: number; // the allocation percentage of an item for the chart
}

interface IArgs {
    items: IValuePercentagesItem[];
    /** The minimum allocation percentage */
    minPercentage?: number;
    /**
     * Valid values: 1-100. Default is 100.
     * How much allocation percentage the items should cover.
     * In case to calculate partial items of a bigger collection of items,
     * define here the desired partial percent that allocation percentage should cover.
     */
    percentageBase?: number;
    /**
     * After the apply of the minPercentage,
     * do not create the "Others" slice if the difference of the new total percentage
     * it up to this percentage. i.e. 5% means if the new percentage is up to 105% do
     * not create the "Others" but shrink them all.
     */
    othersTolerancePercentage?: number;
    skipSort?: boolean;
}

/**
 * Calculate the allocation percentage (the optical percentage) applying min
 * percentage and aggregate the items that don't fit.
 * If the "Others" item is created, will be placed as first.
 * @returns {IValuePercentagesItem[]} - The items (with the same references) with
 * the allocPercentage property updated
 */
export const calcPercentagesWithOthers = (args: IArgs) => {
    const { items: _items, minPercentage = 1, percentageBase = 100, othersTolerancePercentage = 5, skipSort = false } = args;
    let items = _items;

    const amountSum = items.reduce((acc: number, item: IValuePercentagesItem) => acc + item.amount, 0);

    // Calculate the percentages
    items.forEach((item: IValuePercentagesItem) => {
        item.realPercentage = (item.amount * percentageBase) / amountSum;
        item.allocPercentage = item.realPercentage;
    });

    // Sort them
    if (!skipSort) {
        items = items.sort((itemA: IValuePercentagesItem, itemB: IValuePercentagesItem) => itemA.realPercentage - itemB.realPercentage);
    }

    // Exit if all items have same or equal to percentage, no need for aggregation
    if (!items.find(item => item.allocPercentage < minPercentage)) {
        return items;
    }

    // Apply the minPercentage to allocPercentage if it is lower
    items.forEach((item: IValuePercentagesItem) => {
        if (item.allocPercentage < minPercentage) {
            item.allocPercentage = minPercentage;
        }
    });

    // If the allocPercentage exceeds the tolerance
    if (getAllocPercentageSum(items) - percentageBase > othersTolerancePercentage) {
        // Create the "Others" item, for the items that doesn't fit
        createOthers(items, minPercentage, percentageBase);
    } else {
        // Otherwise fit all items in the given space without reducing the items with percentage === minPercentage

        let sumOfMinPercentage = 0;
        let sumOfBigEnoughPercentage = 0;
        let countItemBiggerThanMin = 0;

        items.forEach(item => {
            if (item.allocPercentage <= minPercentage) {
                sumOfMinPercentage += item.allocPercentage;
            } else {
                sumOfBigEnoughPercentage += item.allocPercentage;
                countItemBiggerThanMin++;
            }
        });

        if (percentageBase - sumOfMinPercentage - countItemBiggerThanMin * minPercentage < 0) {
            createOthers(items, minPercentage, percentageBase);
            return;
        }

        const availableSpaceForCompressableItems = percentageBase - sumOfMinPercentage;

        items.forEach((item: IValuePercentagesItem) => {
            if (item.allocPercentage > minPercentage) {
                item.allocPercentage = (item.allocPercentage * availableSpaceForCompressableItems) / sumOfBigEnoughPercentage;
            }
        });

        // If again cannot fit, because there wasn't enough space from the compressables, create the "Others" item
        if (round(getAllocPercentageSum(items)) > round(percentageBase)) {
            createOthers(items, minPercentage, percentageBase);
        }
    }
};

/**
 * Create the "Others" item adding anything that doesn't fit as first new item
 * @param {IValuePercentagesItem[]} items - The items that will be proceeded
 * @param {number} minPercentage - The minimum allocation percentage
 * @param {number} percentageBase - How much allocation percentage the items should cover.
 */
export const createOthers = (items: IValuePercentagesItem[], minPercentage: number, percentageBase: number): void => {
    let othersValue = 0;
    let othersRealPercentage = 0;
    let allocPercentageSum = getAllocPercentageSum(items);

    // Add to "Others" items that doesn't fit
    const roundedPercentageBase = round(percentageBase);
    while (items.length && round(othersRealPercentage + allocPercentageSum) > roundedPercentageBase) {
        const itemForTheOthers = items.shift()!;
        othersValue += itemForTheOthers.amount;
        othersRealPercentage += itemForTheOthers.realPercentage;
        allocPercentageSum -= itemForTheOthers.allocPercentage;
    }

    // Calculate the alloc percentage
    let othersAllocPercentage = percentageBase - allocPercentageSum;

    // If the othersAllocPercentage is too small, make it minPercentage and subtract from the other items proportional
    if (othersAllocPercentage < minPercentage) {
        const differ = minPercentage - othersAllocPercentage;
        othersAllocPercentage = minPercentage;

        if (items.length > 0) {
            const subtractPortionForEvery = differ / items.length;
            items.forEach((item: IValuePercentagesItem) => {
                item.allocPercentage = item.allocPercentage - subtractPortionForEvery;
            });
        }
    }

    // Add the new "Others" item as the first one
    items.unshift({
        amount: othersValue,
        allocPercentage: othersAllocPercentage,
        realPercentage: othersRealPercentage,
    });
};

const getAllocPercentageSum = (items: IValuePercentagesItem[]): number => {
    return items.reduce((acc: number, item: IValuePercentagesItem) => acc + item.allocPercentage, 0);
};

const round = (value: number, digits = 5): number => {
    return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
};
