import { useMemo } from "react";
import { useTranslation } from "next-i18next";
import { usePortfolioChartQuery } from "src/api/client/portfolio-chart.api";
import { useAssetColor } from "src/hooks/portfolio/use-asset-color";
import { useWeightageFormatter } from "src/hooks/portfolio/use-weightage-formatter";
import { convertUnderlyingsToAssetGroupsWithOthers } from "./chart-utils";
import { getAssetSeries, getPortfolioChartConfig } from "./configs";

export const usePortfolioChart = (wikifolioId: string) => {
    const colorMap = useAssetColor();
    const formatWeightage = useWeightageFormatter();
    const { t } = useTranslation("wf-detail");
    const { isLoading, isError, data } = usePortfolioChartQuery(wikifolioId);

    const computedData = useMemo(() => {
        if (!data?.portfolio.hasWeighting) {
            return null;
        }

        const assetGroups = convertUnderlyingsToAssetGroupsWithOthers({ data, othersLabel: t("others") });
        const amountSum = assetGroups.reduce((acc, assetGroup) => acc + assetGroup.amount, 0);

        const series = assetGroups
            .flatMap(assetGroup => assetGroup.underlyings)
            .reverse()
            .map(asset => getAssetSeries(asset, colorMap[asset.assetType], formatWeightage(asset.amount / amountSum, 1)));
        const config = getPortfolioChartConfig(series);

        return { assetGroups, config, amountSum };
    }, [colorMap, data, formatWeightage, t]);

    return {
        isLoading,
        isError: isError,
        hasWeighting: Boolean(data?.portfolio.hasWeighting),
        ...(computedData ?? {}),
    };
};
